import React, { useState, useCallback, useRef } from "react"

import grid from "../../scss/flexboxgrid.module.scss"
import style from "./vdp.module.scss"

import VehicleRecommendation from "../recommendation/VehicleRecommendation"

export default function VDPRecommended({ recommendation, slug, buttons, unavailableImg, catchActionData, fallbackText, ShowLocationFilter }) {
  const localStorage = typeof window !== 'undefined' && window.localStorage
  const [StateFavorites, setFavorites] = useState(getFavorites());

  const observer = useRef()
  const [renderLazyItem, toggleLazyItem] = useState(false)
  const lazyItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        toggleLazyItem(true)
      }
    });
    if (node)
      observer.current.observe(node);
  }
  )

  function getFavorites() {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  function isFavorite(vin) {
    return StateFavorites.findIndex((v) => (v == vin)) !== -1
  }

  function favoriteItem(vin) {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem", JSON.stringify(theFavs));
    setFavorites(theFavs);
  }

  function formatPrice(price, defaultPrice) {
    let thePrice = (defaultPrice != undefined && price == 0
      ? defaultPrice
      : '$' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    return thePrice
  }

  const renderRecommendation = () => (
    recommendation.slice(0, Math.min(recommendation.length, 4)).map((vehicle, idx) => (
      <li
        className={`${grid["col-lg-3"]} ${grid["col-md-4"]} ${grid["col-sm-6"]} ${grid["col-xs-12"]} vehicleCard`}
        style={{ padding: "0 0.5em 0.5em 0" }}
        key={idx}
      >
        <VehicleRecommendation
          fallbackText={fallbackText}
          isFavorite={isFavorite(vehicle.VIN)}
          favorite={favoriteItem}
          theSlug={slug}
          item={vehicle}
          unavailableImg={unavailableImg}
          SRPCardBtns={buttons}
          formatPrice={formatPrice}
          catchActionData={catchActionData}
          ShowLocationFilter={ShowLocationFilter}/>
      </li>
    )))

  return (
    <>
      {recommendation.length > 0 ?
        <section ref={lazyItem} className={style["other-vehicles"]}>
          <h2 className={style["other-vehicles__header"]}>
            Recommended Vehicles
          </h2>
          <ul class="sr">
            {renderLazyItem && renderRecommendation()}
          </ul>
        </section >
        :
        <> </>
      }
    </>
  )
}
